<template>
  <div>
    <b-card v-for="(item, index) in content" :key="'cross-' + index">
      {{ $t("pages.physicalObjects.cross") }}
      <b-form-input
        :id="'cross-' + index + 'cross'"
        v-model="item.cross"
        type="number"
        @change="changed"
        @input="changed"
      />
      <b-list-group>
        <b-list-group-item
          v-for="(cf, cfi) in item.crossForms"
          :key="'cross-' + index + '-form-' + cfi"
        >
          <b-row>
            <b-col sm="auto">
              <b-form-checkbox
                v-model="cf.certainty"
                :name="index + '-certainty-' + cfi"
                switch
                @change="changed"
              >
                {{
                  cf.certainty
                    ? $t("components.crossesComponent.certain")
                    : $t("components.crossesComponent.uncertain")
                }}
              </b-form-checkbox>
            </b-col>
            <b-col>
              <multiselect
                v-if="crossForms.length > 0"
                v-model="cf.crossForm.id"
                :options="crossForms.map(i => i.id)"
                :custom-label="crossFormSearch"
                :show-labels="false"
                @select="changed"
              >
                <template slot="singleLabel" slot-scope="props">
                  <img :src="cfimgurl(props.option)" class="list-image" />
                  {{ crossFormName(props.option) }}
                </template>
                <template slot="option" slot-scope="props">
                  <img :src="cfimgurl(props.option)" class="list-image" />
                  {{ crossFormName(props.option) }}
                </template>
              </multiselect>
            </b-col>
          </b-row>
          <sources-selector
            v-if="cf['sources']"
            :id="'cross-' + index + '-form-' + cfi + '-sources'"
            v-model="cf['sources']"
            @change="changed"
          />
          <b-button
            v-t="'components.crossesComponent.crossFormdelete'"
            @click="deleteCrossForm(item, cfi)"
          />
        </b-list-group-item>
      </b-list-group>
      <b-row>
        <b-col sm="auto">
          <b-button
            v-t="'components.crossesComponent.crossFormnew'"
            @click="addCrossForm(item)"
          />
        </b-col>
        <b-col sm="auto">
          <b-button
            v-t="'components.crossesComponent.crossdelete'"
            @click="deleteCross(index)"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-button v-t="'components.crossesComponent.crossnew'" @click="addCross" />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "CrossesComponent",
  components: {
    SourcesSelector: () => import("@/components/SourcesSelector")
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      crossForms: []
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    changed() {
      this.$emit("change");
    },
    addCross() {
      this.content.push({
        cross: null,
        crossForms: []
      });
      this.$emit("change");
    },
    deleteCross(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    addCrossForm(item) {
      item.crossForms.push({
        certainty: true,
        crossForm: {
          id: null
        },
        sources: []
      });
      this.$emit("change");
    },
    deleteCrossForm(item, index) {
      item.crossForms.splice(index, 1);
      this.$emit("change");
    },
    crossFormName(cfid) {
      return this.cfaspect(cfid) + " " + this.cfform(cfid);
    },
    crossFormSearch(cfid) {
      return (
        this.cfaspect(cfid) +
        this.cfform(cfid) +
        " " +
        this.cfaspect(cfid) +
        " " +
        this.cfform(cfid)
      );
    },
    cfaspect(cfid) {
      return this.crossForms.find(x => x.id == cfid).aspect;
    },
    cfform(cfid) {
      return this.crossForms.find(x => x.id == cfid).form;
    },
    cfimgurl(cfid) {
      // FIXME: make url configurable, store images locally?
      return `https://www.runinskrifter.net/img/crosses/${this.cfaspect(
        cfid
      )}/${this.cfaspect(cfid)}${this.cfform(cfid)}.png`;
    }
  },
  apollo: {
    crossForms() {
      return {
        query: gql`
          query crossForms {
            crossForms {
              id
              aspect
              form
            }
          }
        `,
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }

          this.crossForms = result.data.crossForms.sort(
            ({ aspect1, form1 }, { aspect2, form2 }) =>
              aspect1 == aspect2
                ? form1 < form2
                : aspect1.localeCompare(aspect2, "sv")
          );
        }
      };
    }
  }
};
</script>
